import React from "react";
import { View } from "react-native";
import Meld from "./Meld";
import GameHelper from "../GameHelper";
import {
  unnaturalCanastaIsNotAllowed,
  isPlayerCannotMakeMeldWithACard,
  isCardWild,
  isCanasta,
  canAcceptWildCardsInMeld,
  hasMinimumNaturalCards,
  getTotalUnnaturalCanastas,
} from "hffe_game_engine";

const isMeldNatural = (meld) =>
  meld.meldCards.every((card) => !card.card.isWild);

export const getTotalNaturalCanastas = (team) => {
  if (!team || !team.melds) return 0;
  let sum = 0;
  team.melds.forEach(
    (meld) => (sum += isMeldNatural(meld) && isCanasta(meld) ? 1 : 0)
  );

  return sum;
};

function willMakeUnnaturalCanasta(card, meld) {
  if (!meld || !card) return false;
  if (isCardWild(card)) {
    return true;
  }
  return (
    meld.meldCards.some((c) => c.card.isWild) && meld.meldCards.length === 6
  );
}

export const naturalCanastaIsNotAllowed = (meld, team, game) => {
  if (!meld || !team || !game) {
    return false;
  }

  return (
    meld &&
    meld.meldCards.length === 6 &&
    meld.meldCards.every((card) => !card.card.isWild) &&
    getTotalNaturalCanastas(team) >= game.gameConfig.naturalCanastasNeeded &&
    team.unnaturalCanastasLeft > 0
  );
};

const Melds = ({
  game,
  dimmingDisabled = false,
  showRankLabels = false,
  mobileDisplay = false,
  ipadPortraitDisplay = false,
  selectedCard = {},
  viewerTeamMelds = [],
  opponentTeamMelds = [],
  onMeldPressed,
  disabled,
}) => {
  const meldsCardRanks = [
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
    "A",
  ];
  const team = GameHelper.getViewerTeam(game);
  return (
    <View
      style={{
        ...ownStyles.meldsContainer,
        ...(mobileDisplay ? { justifyContent: "center" } : {}),
      }}
    >
      {meldsCardRanks.map((cardRank, i) => {
        const viewerTeamMeld = viewerTeamMelds.find(
          (meld) => meld.cardRank.value === cardRank
        );
        const opponentTeamMeld = opponentTeamMelds.find(
          (meld) => meld.cardRank.value === cardRank
        );

        let notPossibleToAdd = false;
        const { naturalCanastasNeeded, unnaturalCanastasNeeded } =
          game.gameConfig;

        if (selectedCard && !dimmingDisabled) {
          if (
            !(
              getTotalUnnaturalCanastas(team) >= unnaturalCanastasNeeded &&
              getTotalNaturalCanastas(team) >= naturalCanastasNeeded
            )
          ) {
            notPossibleToAdd =
              !isCardWild(selectedCard) &&
              naturalCanastaIsNotAllowed(viewerTeamMeld, team, game);
            notPossibleToAdd =
              notPossibleToAdd ||
              (viewerTeamMeld &&
                viewerTeamMeld.meldCards.length === 6 &&
                willMakeUnnaturalCanasta(selectedCard, viewerTeamMeld) &&
                getTotalUnnaturalCanastas(team) >= unnaturalCanastasNeeded);
          }
          notPossibleToAdd =
            notPossibleToAdd ||
            isPlayerCannotMakeMeldWithACard(cardRank, viewerTeamMeld, team);
          notPossibleToAdd =
            notPossibleToAdd ||
            (!isCardWild(selectedCard) && selectedCard?.rank !== cardRank);
          notPossibleToAdd =
            notPossibleToAdd || (!viewerTeamMeld && isCardWild(selectedCard));

          if (viewerTeamMeld && isCardWild(selectedCard)) {
            notPossibleToAdd =
              notPossibleToAdd ||
              viewerTeamMeld.numberOfWildCardsAllowed <=
                viewerTeamMeld.meldCards.filter((card) => card.isWild).length;
            notPossibleToAdd =
              notPossibleToAdd || !canAcceptWildCardsInMeld(viewerTeamMeld);
            notPossibleToAdd =
              notPossibleToAdd || !hasMinimumNaturalCards(viewerTeamMeld);
            notPossibleToAdd = notPossibleToAdd || isCanasta(viewerTeamMeld);
          }
        }

        return (
          <View
            key={i}
            style={{ width: "100%", marginTop: 5, marginBottom: 5 }}
          >
            <Meld
              dimmed={notPossibleToAdd}
              showRankLabel={showRankLabels}
              cardRank={cardRank}
              mobileDisplay={mobileDisplay}
              ipadPortraitDisplay={ipadPortraitDisplay}
              onMeldPressed={onMeldPressed}
              viewerTeamMeld={viewerTeamMeld}
              opponentTeamMeld={opponentTeamMeld}
              disabled={disabled}
            />
          </View>
        );
      })}
    </View>
  );
};

const ownStyles = {
  meldsContainer: {
    width: "100%",
    flexGrow: 1,
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
};

export default Melds;
